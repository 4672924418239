import {
  Box,
  Columns,
  Container,
  Heading,
  Stack,
  Text,
} from '@spaceship-fspl/components';
import {
  calcInceptionToPerformanceAsAtDateInMonths,
  stringToDate,
} from '@spaceship-fspl/helpers';
import { fontSize } from '@spaceship-fspl/styles';
import { TrackingCategory } from '@spaceship-fspl/tracking';
import { PerformanceCardProps } from 'components/performance-card';
import {
  SuperPrismicPortfolio,
  VoyagerPrismicPortfolio,
} from 'helpers/products';
import React from 'react';
import styled from 'styled-components';

import { PanelSection } from '../panel-section';

const PerformanceAmount = styled(Heading)`
  font-family: 'IBM Plex Mono', monospace;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -2px;
`;

const PerAnnum = styled.span`
  ${fontSize({ xs: '24px', lg: '28px' })}
`;

type PortfolioData<T> = Omit<PerformanceCardProps<T>, 'variant'>;

export interface ReturnsPanelProps<T> {
  title: string;
  tagline: string;
  disclaimer: React.ReactNode;
  portfolio: PortfolioData<T>;
}

export function ReturnsPanel<
  T extends VoyagerPrismicPortfolio | SuperPrismicPortfolio,
>({
  title,
  tagline,
  disclaimer,
  portfolio,
}: ReturnsPanelProps<T>): React.ReactElement {
  const isVoyager = [
    'universe',
    'origin',
    'earth',
    'galaxy',
    'explorer',
  ].includes(portfolio.type);

  const inceptionDate = stringToDate(portfolio.inceptionDate ?? '');
  const performanceAsAtDate = stringToDate(portfolio.performanceAsAt ?? '');
  const annualisedPerformanceDataInMonths =
    calcInceptionToPerformanceAsAtDateInMonths(
      inceptionDate,
      performanceAsAtDate,
    );

  return (
    <TrackingCategory category="Returns Panel">
      <PanelSection>
        <Container>
          <Stack spaceY={{ xs: 'lg', lg: 'xl' }}>
            <Stack spaceY={{ xs: 'sm', lg: 'md' }}>
              <Heading component="h2" variant={2} align="center">
                {title}
              </Heading>

              <Text variant={1} align="center" color="neutral.080">
                {tagline}
              </Text>
            </Stack>

            <Columns
              spaceX={{ xs: 'none', md: 'md' }}
              spaceY={{ xs: 'md', lg: 'lg' }}
            >
              <Columns.Column
                offset={{ xs: 0, md: 1 / 12 }}
                width={{ xs: 1, md: 5 / 12 }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  height="100%"
                  justifyContent="center"
                  backgroundColor="indigo.100"
                  borderRadius="md"
                  minHeight="205px"
                  paddingY={{ xs: 'lg', lg: 'xl' }}
                  paddingX={{ xs: 'lg', md: 'xl', lg: 'xxl' }}
                >
                  <Stack spaceY={{ xs: 'xxxs', lg: 'xxs' }}>
                    <PerformanceAmount
                      variant={1}
                      align="center"
                      color={isVoyager ? 'indigo.020' : 'mint.030'}
                    >
                      {portfolio.oneYearPerformancePercentage === 'N/A'
                        ? 'x.xx'
                        : portfolio.oneYearPerformancePercentage}
                      %
                    </PerformanceAmount>
                    {portfolio.type === 'galaxy' ||
                    portfolio.type === 'explorer' ? (
                      <Text align="center" variant={2} color="neutral.000">
                        Investment performance data for the {portfolio.title}{' '}
                        will be available after one full year of history. The{' '}
                        {portfolio.title} was funded on{' '}
                        {portfolio.inceptionDate}.
                      </Text>
                    ) : (
                      <Text align="center" variant={2} color="neutral.000">
                        Over the year ending {portfolio.performanceAsAt}
                      </Text>
                    )}
                  </Stack>
                </Box>
              </Columns.Column>

              <Columns.Column width={{ xs: 1, md: 5 / 12 }}>
                <Box
                  display="flex"
                  flexDirection="column"
                  height="100%"
                  justifyContent="center"
                  backgroundColor="indigo.100"
                  borderRadius="md"
                  minHeight="205px"
                  paddingY={{ xs: 'lg', lg: 'xl' }}
                  paddingX={{ xs: 'lg', md: 'xl', lg: 'xxl' }}
                >
                  <Stack spaceY={{ xs: 'xxxs', lg: 'xxs' }}>
                    <PerformanceAmount
                      variant={1}
                      align="center"
                      color={isVoyager ? 'indigo.020' : 'mint.030'}
                    >
                      {portfolio.sinceInceptionPerformancePercentage === 'N/A'
                        ? 'x.xx'
                        : portfolio.sinceInceptionPerformancePercentage}
                      %<PerAnnum>&nbsp;pa</PerAnnum>
                    </PerformanceAmount>

                    {portfolio.type === 'galaxy' ||
                    portfolio.type === 'explorer' ? (
                      <Text align="center" variant={2} color="neutral.000">
                        Investment performance data for the {portfolio.title}{' '}
                        will be available after one full year of history. The{' '}
                        {portfolio.title} was funded on{' '}
                        {portfolio.inceptionDate}.
                      </Text>
                    ) : (
                      <Text align="center" variant={2} color="neutral.000">
                        {isVoyager
                          ? 'Annualised since the Funded Date*'
                          : 'Annualised over the life of the fund'}{' '}
                        ({portfolio.inceptionDate} to{' '}
                        {portfolio.performanceAsAt}) (
                        {annualisedPerformanceDataInMonths} months)
                      </Text>
                    )}
                  </Stack>
                </Box>
              </Columns.Column>

              <Columns.Column
                offset={{ xs: 0, md: 1 / 12 }}
                width={{ xs: 1, md: 10 / 12 }}
              >
                <Text
                  variant={3}
                  align="center"
                  color="neutral.085"
                  component="div"
                >
                  {disclaimer}
                </Text>
              </Columns.Column>
            </Columns>
          </Stack>
        </Container>
      </PanelSection>
    </TrackingCategory>
  );
}
